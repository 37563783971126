import React from "react"
import "../components/styles.css"
import SEO from "../components/seo"
import "../components/styles.css"
import Header from "../components/Header"
import marichem from "../img/navbar-logo.png"
// import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <div>
    <Header />
    <SEO title="404: Not found" />
    <section className="marichem container-fluid position-relative">
      <div className="pt-5 d-flex text-center align-items-center justify-content-center">
        <a href="/">
          <img src={marichem} alt="Icon Marichem logo" />
        </a>
      </div>
      <div className="row h-75 d-flex text-center align-items-center justify-content-center text-white">
        <div className="content ">
          <p>You hit a route that doesn't exist... the sadness.</p>
          <a className="btn btn-warning btn-md" href="/" role="button">
            Go Back To Home
          </a>
        </div>
      </div>
    </section>
  </div>
)

export default NotFoundPage
